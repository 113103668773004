// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bn_k3";
export var caseStudyBackground__lineColor = "bn_k0";
export var caseStudyHead = "bn_kW";
export var caseStudyHead__imageWrapper = "bn_kX";
export var caseStudyProjectsSection = "bn_k4";
export var caseStudyQuote__bgLight = "bn_kY";
export var caseStudyQuote__bgRing = "bn_kZ";
export var caseStudyVideo__ring = "bn_k2";
export var caseStudy__bgDark = "bn_kV";
export var caseStudy__bgLight = "bn_kT";